<template>
    <v-container class="pa-0" fluid>
        <v-card>
            <v-card-text class="text-left">
                <v-form ref="form">
                    <!-- Objectives Section -->

                    <p class="text-h6">
                        <v-icon left>
                            mdi-bullseye-arrow
                        </v-icon>
                        Objetivos de Entrenamiento
                    </p>
                    <p v-show="!isCoachView">
                        Selecciona tu/tus objetivos de entrenamiento, esto nos ayudará a personalizar tu plan de
                        entrenamiento.
                    </p>
                    <v-autocomplete v-model="user.objectives" :items="availableObjectives" label="Seleccionar"
                        single-line :search-input.sync="search" class="rounded-lg" filled rounded multiple
                        :rules="[maxSelection(5, 'objectives')]" chips>
                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to
                                        create a new one
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-autocomplete>


                    <!-- Injuries Section -->


                    <p class="text-h6">
                        <v-icon left>
                            mdi-bandage
                        </v-icon>
                        Lesiones
                    </p>
                    <p v-show="!isCoachView">
                        Por favor, indícanos si has tenido alguna lesión en el pasado o actualmente.
                    </p>

                    <v-form ref="form">
                        <v-expansion-panels v-model="injuriesPanel">
                            <v-expansion-panel v-for="(injury, index) in user.injuries" :key="index">
                                <v-expansion-panel-header>

                                    <v-text-field filled rounded class="rounded-lg" label="Nombre de lesión"
                                        :rules="[v => !!v || 'Este campo es requerido']" maxlength="50"
                                        v-model="injury.nombre">
                                        <template v-slot:append>
                                            <v-icon :color="injury.recuperado ? 'green lighten-3' : 'red lighten-3'">
                                                mdi-medical-bag
                                            </v-icon>
                                        </template>
                                    </v-text-field>




                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-menu width="100%" ref="menu" v-model="menu" :close-on-content-click="false"
                                        transition="scale-transition" offset-y max-width="300" min-width="300">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field filled rounded class="rounded-lg mt-2" hide-details
                                                v-model="injury.cuando" label="Cuando" append-icon="mdi-calendar"
                                                readonly dense v-bind="attrs" v-on="on" required></v-text-field>
                                        </template>
                                        <v-date-picker v-model="injury.cuando" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu = false">Cancelar</v-btn>
                                            <v-btn text color="primary" @click="menu = false">Confirmar</v-btn>
                                        </v-date-picker>
                                    </v-menu>





                                    <!--  <v-text-field filled rounded class="rounded-lg mt-2" hide-details label="Como"
                                    append-icon="mdi-help-circle" v-model="injury.como"></v-text-field> -->
                                    <v-text-field filled rounded class="rounded-lg mt-2" hide-details label="Deporte"
                                        maxlength="50" append-icon="mdi-soccer" v-model="injury.deporte"></v-text-field>
                                    <v-checkbox class="mb-4" hide-details="" label="Recuperado"
                                        v-model="injury.recuperado"></v-checkbox>


                                    <v-textarea auto-grow rows="1" v-model="injury.observation" filled rounded
                                        class="rounded-lg my-2" hide-details label="Observaciones"
                                        v-if="($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin' || $store.state.Auth.token.claims.type == 'entrenador')"
                                        maxlength="150"></v-textarea>

                                    <v-card-actions class="py-0">
                                        <v-spacer></v-spacer>
                                        <v-btn @click="removeInjury(index)">
                                            <v-icon left>
                                                mdi-delete
                                            </v-icon>
                                            Borrar Lesion</v-btn>

                                    </v-card-actions>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-form>
                    <v-btn @click="addInjury" class="mt-2">
                        <v-icon left>
                            mdi-plus
                        </v-icon>
                        Agregar lesión
                    </v-btn>
                    <!--  <v-select v-model="user.objectivesMarks" :items="availableObjectivesMarks"
                        label="Objectives (Marks)" multiple chips v-if="isCoachOrAdmin"></v-select>
 -->
                    <!-- Musical Tastes Section -->
                    <!--  <v-subheader>Musical Tastes</v-subheader>
                    <v-autocomplete v-model="user.musicalTastes.artists" :items="availableArtists"
                        label="Select Artists" class="rounded-lg" filled rounded multiple chips
                        prepend-inner-icon="mdi-music" :rules="[maxSelection(5, 'artists')]"></v-autocomplete>
                    <v-autocomplete v-model="user.musicalTastes.genres" :items="availableGenres" label="Select Genres"
                        class="rounded-lg" filled rounded multiple chips prepend-inner-icon="mdi-music-box-multiple"
                        :rules="[maxSelection(5, 'genres')]"></v-autocomplete> -->
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-3" @click="updateUserData" :loading="loading"
                    v-show="!$props.isEvaluationView">
                    <v-icon left>
                        mdi-content-save
                    </v-icon>
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import { getFirestore, doc, setDoc, updateDoc } from 'firebase/firestore'


import { logAuditEvent } from '@/error/audit.js';

export default {
    props: {
        user: {
            type: Object,
            required: true
        },
        isCoachView: {
            type: Boolean,
            default: false
        },
        isEvaluationView: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            menu: false,
            search: '',
            injuriesPanel: [],
            availableObjectives: [
                'Desarrollo de la fuerza',
                'Agilidad',
                'Resistencia',
                'Flexibilidad',
                'Velocidad',
                'Potencia',
                'Coordinación',
                'Hipertrofia',
                'Movilidad',
                'Estabilidad',
                'Equilibrio',
                'Postura',
                'Rehabilitación',
                'Prevención de lesiones',
                'Perder peso'
            ],
            availableObjectivesMarks: [
                'Objective Mark 1',
                'Objective Mark 2'
                // Add other marks as needed
            ],
            availableArtists: [
                'Artist 1',
                'Artist 2'
                // Add other artists as needed
            ],
            availableGenres: [
                'Genre 1',
                'Genre 2'
                // Add other genres as needed
            ]
        }
    },
    methods: {
        addInjury() {
            this.user.injuries.push({
                nombre: null,
                cuando: null,
                como: null,
                deporte: null,
                recuperado: false
            })

            this.$forceUpdate()
        },
        removeInjury(index) {
            this.user.injuries.splice(index, 1)
        },
        async updateUserData() {

            try{
                this.loading = true;
                const db = getFirestore()
                const userRef = doc(db, 'users', this.$props.user.id)

                await updateDoc(userRef, {
                    objectives: this.user.objectives || [],
                    injuries: this.user.injuries || []
                })

                this.$notify({
                    type: 'success',
                    title: 'Exito',
                    text: 'Datos actualizados correctamente.'
                })

                logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `User preferences updated ${JSON.stringify(this.user)}`)


                return true
            }
            catch(e){
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error updating user preferences ${e.message}`)
                this.$notify({
                    type: 'error',
                    title: 'Error',
                    text: 'Error al actualizar los datos.'
                })
                return false
            }
            finally{
                this.loading = false
            }
            
            

        },
        maxSelection(max, field) {
            return v => {
                if (v.length <= max) return true
                return `Maximo ${max} seleccionados.`
            }
        }
    }
}
</script>

<style scoped>
.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__selections {
    padding-top: 26px !important;
}
</style>
